"use strict";

$(function () {
  $(document).on('scroll', function () {
    if ($(document).scrollTop() > 50) {
      $('#navbar-main').addClass('scrolled');
    } else {
      $('#navbar-main').removeClass('scrolled');
    }
  }).trigger('scroll');
});